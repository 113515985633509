<script>
import { i18n } from '@/i18next'
import store from '@/store'
import acl from '@/acl'

export default {
  render (h) {
    const missingHelpIcon = h('span', {
      class: 'mdi mdi-alert',
      style: {
        'margin': '0 .5em',
        color: 'var(--color-warning-500)'
      }
    })
    const isSuperuser = acl.isSuperuser()
    const messageByNames = store.getters['help/messageByNames']
    const makeLink = (name, label, hasHelp = true) => {
      const children = [makeRouterLink(name, label)]
      if (hasHelp && isSuperuser && !messageByNames[name]) {
        children.push(missingHelpIcon)
        children.push(i18n.t('core.help.helpNotFound'))
      }
      return h('div', children)
    }
    const makeRouterLink = (name, label) => h('router-link', {
      props: {
        to: { name }
      },
      class: 'link'
    }, [label])
    const makeLinksGroup = (children) => h('div', {
      class: 'link-group'
    }, [children])
    const routes = [
      makeLink('factory', i18n.t('core.router.factory')),
      makeLink('map.factory', i18n.t('core.router.factoryMap')),
      makeLinksGroup([
        makeLink('map.editor', i18n.t('core.factory.map.editor'))
      ]),
      makeLink('dataInput', i18n.t('core.router.dataInput')),
      makeLinksGroup([
        makeLink('dataInput.batch', i18n.t('core.dataInput.forms.batch')),
        makeLink('dataInput.water', i18n.t('core.dataInput.forms.water')),
        makeLink('dataInput.feeding', i18n.t('core.dataInput.forms.feeding')),
        makeLink('dataInput.mortality', i18n.t('core.dataInput.forms.mortality')),
        makeLink('dataInput.fishWeight', i18n.t('core.dataInput.forms.fishWeight')),
        makeLink('dataInput.catch', i18n.t('core.dataInput.forms.catch')),
        makeLink('dataInput.moving', i18n.t('core.dataInput.forms.moving')),
        makeLink('dataInput.seeding', i18n.t('core.dataInput.forms.seeding')),
        makeLink('dataInput.resetTanks', i18n.t('core.dataInput.forms.resetTanks')),
        makeLink('dataInput.feedRation', i18n.t('core.dataInput.forms.feedRation'))
      ]),
      makeLink('stocks', i18n.t('stocks.router.stocks')),
      makeLink('control', i18n.t('core.router.control')),
      makeLink('events', i18n.t('core.router.events')),
      makeLink('summary', i18n.t('core.router.summary'), false),
      makeLinksGroup([
        makeLink('summary.feeding', i18n.t('core.factorySummary.by.feeding')),
        makeLink('summary.feedingGrowth', i18n.t('core.factorySummary.by.feedingGrowth')),
        makeLink('summary.medicines', i18n.t('core.factorySummary.by.medicines')),
        makeLink('summary.mortality', i18n.t('core.factorySummary.by.mortality')),
        makeLink('summary.catch', i18n.t('core.factorySummary.by.catch'))
      ]),
      makeLink('reports', i18n.t('core.router.reports')),
      makeLinksGroup([
        makeLink('reports.full', i18n.t('core.reports.factoryReport.title')),
        makeLink('reports.brief', i18n.t('core.reports.factoryBriefReport.title')),
        makeLink('reports.fishSeeding', i18n.t('core.reports.fishSeedingReport.title')),
        makeLink('reports.fishCatch', i18n.t('core.reports.fishCatchReport.title')),
        makeLink('reports.fishMortality', i18n.t('core.reports.fishMortalityReport.title')),
        makeLink('reports.fishMoving', i18n.t('core.reports.fishMovingReport.title')),
        makeLink('reports.feeding', i18n.t('core.reports.feedingReport.title')),
        makeLink('reports.stocksState', i18n.t('core.reports.stocksStateReport.title')),
        makeLink('reports.stockFeeds', i18n.t('core.reports.stockFeedsReport.title')),
        makeLink('reports.stockMedicines', i18n.t('core.reports.stockMedicinesReport.title'))
      ]),
      makeLink('productionPlans', i18n.t('supervisor.router.supervisor')),
      makeLink('registries', i18n.t('core.router.registries')),
      makeLinksGroup([
        makeLink('registries.feedProducers', i18n.t('core.registries.feedProducers'), false),
        makeLinksGroup([
          makeLink('registries.feedProducers.local', i18n.t('core.registries.source.local')),
          makeLink('registries.feedProducers.catalog', i18n.t('core.registries.source.catalog'))
        ]),
        makeLink('registries.feedingPlans', i18n.t('core.registries.feedingPlans'), false),
        makeLinksGroup([
          makeLink('registries.feedingPlans.local', i18n.t('core.registries.source.local')),
          makeLink('registries.feedingPlans.catalog', i18n.t('core.registries.source.catalog'))
        ]),
        makeLink('registries.medicines', i18n.t('core.registries.medicines')),
        makeLink('registries.commonReasons', i18n.t('core.registries.commonReasons'))
      ]),
      makeLink('iot.devices', i18n.t('iot.router.thermooximeters')),
      makeLink('administration', i18n.t('core.router.administration'), false),
      makeLinksGroup([
        makeLink('administration.users', i18n.t('core.acl.users.label')),
        makeLink('administration.roles', i18n.t('core.acl.roles.label')),
        makeLink('administration.acl', i18n.t('core.acl.rules.label')),
        makeLink('administration.profile', i18n.t('core.user.profilePage.title')),
        makeLink('administration.auth-log', i18n.t('core.auth.log.title')),
        makeLink('administration.preferences-factory', i18n.t('core.preferences.factory'))
      ]),
      makeLink('notifications', i18n.t('core.router.notifications'), false),
      makeLinksGroup([
        makeLink('notifications.new', i18n.t('core.notifications.category.new')),
        makeLink('notifications.archive', i18n.t('core.notifications.category.archive'))
      ])
    ]
    return h('div', {
      class: 'help-site-map'
    }, routes)
  }
}
</script>

<style lang="scss">
.help-site-map {
  height: 100%;
  overflow: auto;
  padding: 1em;
  .link-group {
    margin-left: 1em;
  }
}
</style>
